<template>
  <section class="relative w-full aspect-video md:max-h-[440px] items-center justify-center flex flex-col space-y-4 lg:space-y-12 py-10 md:pt-20 px-5" style="background-size:100% 100%;background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAOCAYAAABO3B6yAAAAAXNSR0IArs4c6QAAAY5JREFUSEutlAtvgzAMhPMggXZ97P//WaZzcuYItNWmIUURJY0/n8+O18f3Gk6edV0DFz7HGG2llELO2XYs/q5X8BvOlVJs1VpDKTXUWsI0TXaH/fdyfxoAXvggMJ5XACnnkN8AEBSBsDaABkMAS2C5PVYNrhAEGRWwDKFCV2QU0JVC8A5AJRTAFJi/7gcFmP22Q/7gslPisQQGjEtDOwuZqQABqAoTiPV6cw+MSrxTwGsIGUUCXEYAQGgZNHv//7RcvQSfADRzNaGWgAC4C0GoApXgu6uX58uuBGd+OPWAmNDq041LGO0YqkDzsYvMA6kufwJwCXt7qm8ITB8wa81+80+ZHYDZc6cH+P6pBHZRV0IV0FLoDGmGneoO4D9LENEJvRsIcRhiIZfVSETK3VDqrkZylC0lmItTEPtxlPI+DezB+wyxMwRQo6GRmq+2Ka2j+NUcoA94lp1gk7NPz82ADbwBtOinc11dzbp6C6ITevr4pp4ZFeD43nWAK/ALAIc4jGLUgblsyYy1HwF+ADvlVuhYLAImAAAAAElFTkSuQmCC');">
    <img
      :src="bg"
      :srcset="srcset"
      loading="lazy"
      fetchpriority="low"
      class="z-0 absolute inset-0 object-cover w-full h-full"
      alt="The service that MattePaint offers is second to none"
    />
    <img src="~/assets/img/home/logos/dneg.png" loading="lazy" fetchpriority="low" class="z-10 !mt-10" alt="DNEG Logo" />
    <strong class="z-10 w-full max-w-4xl text-xl md:text-2xl font-bold text-lightgray-100 text-center">“The service that MattePaint offers is second to none for photo resources for professional Matte Painters and Environment Artists.”</strong>
    <div class="z-10 flex space-x-0 md:space-x-4 space-y-1 md:space-y-0 items-center flex-col md:flex-row">
      <img src="~/assets/img/avatar_nickmarshall@2x.jpg" alt="Nick Marshall" loading="lazy" fetchpriority="low" class="rounded-full w-10 h-10 bg-white object-cover" />
      <span class="text-base font-medium text-lightgray-100">Nick Marshall</span>
      <span class="text-orange-400 font-bold text-xl hidden md:flex">/</span>
      <span class="text-lg font-medium text-orange-400 md:text-lightgray-300">Director Of Visual Effects, DNEG</span>
    </div>
    <div class="z-10 w-full flex justify-end flex-col items-end !mt-16">
      <strong class="text-xs font-medium text-lightgray-300 md:text-lightgray-100">Ant-Man and the Wasp</strong>
      <p class="text-xs text-300 font-normal text-lightgray-300 md:text-lightgray-100">
        Image Courtesy of DNEG © 2018 MARVEL
      </p>
    </div>
  </section>
</template>

<script>
import xlbg from '~/assets/img/home/quote/2x.jpg';
import lbg from '~/assets/img/home/quote/1x.jpg';
import mbg from '~/assets/img/home/quote/mobile.jpg';

export default {
  data() {
    return {
      bg: mbg, // default image
      srcset: `${mbg} 500w, ${lbg} 1024w, ${xlbg} 1920w`,
    };
  }
}
</script>